(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name login.factory:Login
   *
   * @description
   *
   */
  angular
    .module('login')
    .factory('Login', Login);

  function Login($http, consts) {
    var LoginBase = {};

    LoginBase.createUserAndGetCode = function(phone) {
      return $http({
        method: "POST",
        url: consts.serverUrl + "Account/CreateUserAndGetCode",
        data: {
          franchiseId: consts.franchiseId,
          phone: phone
        }
      });
    };

    LoginBase.resendCode = function(phone) {
      return $http({
        method: "POST",
        url: consts.serverUrl + "Account/ResendCode",
        data: {
          franchiseId: consts.franchiseId,
          phone: phone
        }
      });
    }

    LoginBase.verifyLoginCode = function(phone, code) {
      return $http({
        method: "POST",
        url: consts.serverUrl + "Account/VerifyLoginCode",
        data: {
          franchiseId: consts.franchiseId,
          phone: phone,
          code: code
        }
      });
    }

    return LoginBase;
  }
}());
